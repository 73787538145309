@font-face {
  font-family: 'boogaloo';
  src: url('./assets/fonts/boogaloo-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/boogaloo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'broadway';
  src: url('./assets/fonts/BroadwayCopyistTextExt.woff2') format('woff2'),
  url('./assets/fonts/BroadwayCopyistTextExt.ttf') format('truetype'),
  url('./assets/fonts/BroadwayCopyistTextExt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


h1,
h2,
h3,
h4,
h5 {
  font-family: "broadway", "boogaloo", Helvetica, sans-serif;
  letter-spacing: 2px;
}

p,
table {
  line-height: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif

}
