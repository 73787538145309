.swal2-popup {
  .swal2-image {
    width: 40px;
  }

  .permission-checkbox {
    float: left;
    text-align: left;
  }
}
