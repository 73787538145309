.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing-gentle {
  0% {
    transform: rotate(0deg) scale(1.1);
    animation-timing-function: ease-out;
  }
  25% {
    transform: rotate(-10deg) scale(1.1);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(0deg) scale(1.1);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(10deg) scale(1.1);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(0deg) scale(1.1);
    animation-timing-function: ease-out;
  }
}

@keyframes deleteWiggle {
  0% {
    transform: rotate(-10deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(15deg);
    animation-timing-function: ease-out;
  }
}
