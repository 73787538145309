.bord {
  background: url('/assets/images/bord.png') center no-repeat;
  background-size: contain;
  text-align: center;
  position: relative;
  transition: all .1s ease;
  width: 100%;
  height: 100%;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 3;
    width: 25px;
    text-align: right;
    filter: drop-shadow(0 0 1px #4b312e);
    animation-name: deleteWiggle;
    animation-duration: .75s;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
  }

  &__upload {
    max-width: 100%;
    max-height: 100%;
    margin-top: 3px;
  }

  &.bord--hook--only {
    background: none;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  &.bord--hook {
    background: url('/assets/images/bord_hook.png') center no-repeat;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  &.bord--drawing {
    background: none;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  &.bord--no-hook {
    background: none;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  &.bord--bar__light,
  &.bord--bar {
    background: url('/assets/images/bord-bar.png') center no-repeat;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  &.bord--bar__light {
    background: url('/assets/images/bord-bar_light.png') center no-repeat;
    background-size: contain;
    z-index: 5;
    position: relative;
  }

  img.bord__icon {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    margin-top: 3px;
  }
}
